import htmx from "htmx.org/dist/htmx.esm.js";
window.htmx = htmx;

import _hyperscript from "hyperscript.org";
window._hyperscript = _hyperscript;
_hyperscript.browserInit();

// Uncomment below code snippet to enable htmx logging
// window.htmx.logger = function (elt, event, data) {
//   if (console) {
//     console.log(event, elt, data);
//   }
// };

document.addEventListener("htmx:afterRequest", function (event) {
  const hxRedirect = event.detail.xhr.getResponseHeader("hx-redirect");
  if (
    hxRedirect &&
    hxRedirect == window.location.pathname + window.location.hash
  )
    window.location.reload();
});

document.addEventListener("DOMContentLoaded", function () {
  // Add global htmx:afterSettle event listener
  htmx.on("htmx:afterSettle", function (_event) {
    if (typeof initFlowbite === "function") {
      initFlowbite();
    }
  });
});
