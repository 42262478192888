// Listen for hijack events globally
document.addEventListener("DOMContentLoaded", function () {
  // Add listener for Django Hijack's success event
  document.addEventListener("hijack:success", function () {
    console.log("Hijack successful");
  });

  // Add listener for Django Hijack's release event
  document.addEventListener("hijack:release", function () {
    console.log("Hijack released");
  });

  // Check if user is hijacked and change navbar color
  const navbar = document.getElementById("main-navbar");
  const hijackNotification = document.getElementById("djhj");
  if (hijackNotification) {
    navbar.classList.add("navbar-hijacked");
    navbar.classList.remove("bg-white", "dark:bg-gray-800");
  }
});
