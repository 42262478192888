// Function to clear screener-related localStorage items
function clearScreenerStorage() {
  console.log("Starting storage clearing process...");

  // Remove specific screener related keys
  const keysToRemove = [
    "stockScreenerFilters",
    "tableSettings",
    "currentScreenerName",
    "currentScreenerId",
  ];

  keysToRemove.forEach((key) => {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
      console.log(`Removed ${key}`);
    }
  });

  // Reset the header title
  const titleElement = document.getElementById("screenerTitle");
  if (titleElement) {
    titleElement.textContent = "Screener";
  }

  // Clear all tabulator- prefixed keys
  Object.keys(localStorage)
    .filter((key) => key.startsWith("tabulator-"))
    .forEach((key) => {
      localStorage.removeItem(key);
      console.log(`Removed tabulator key: ${key}`);
    });

  console.log("Storage clearing completed");
}

// Make it available globally
window.clearScreenerStorage = clearScreenerStorage;
